import MainCard from "../Theme/Cards/MainCard";
import { Grid, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
// import Logo from "../../../Components/Theme/Logo";
import { themeColors } from "../../Config/Themes/theme";
import { useTranslation } from "react-i18next";

// eslint-disable-next-line react/prop-types
const NoAccessCard = () => {
  const theme = useTheme();
  const colors = themeColors(theme.palette.mode);
  const { t } = useTranslation();
  return (
    <MainCard className="min-h-fit">
      <Grid
        container
        spacing={2}
        alignItems="flex-start"
        justifyContent="flex-start"
      ></Grid>
      <Typography
        variant="h1"
        className="text-center"
        gutterBottom
        sx={{ color: colors.primary.main, mt: 1, mb: 2 }}
      >
        {t("noAccessCard.Error")}
      </Typography>
      <Typography
        variant="h1"
        className="text-center"
        gutterBottom
        sx={{ color: colors.redAccent.main, mt: 5, mb: 5 }}
      >
        {t("noAccessCard.YouHaveNotAccessForThisAction")}
      </Typography>
      <Typography
        sx={{ color: colors.greyAccent.dark2 }}
        className="text-center justify-center items-center text-2xl "
      >
        {t(
          "noAccessCard.YouNeedToHaveTheCorrectRoleOrClaimsInOrderToBeAuthorizedForThisAction"
        )}
      </Typography>
    </MainCard>
  );
};

export default NoAccessCard;
