import { action, makeObservable, observable } from "mobx";
import { createContext, useContext } from "react";

class ThemeStore {
  isMenuOpen = true;

  activeMenuItem = null;

  constructor() {
    makeObservable(this, {
      isMenuOpen: observable,
      activeMenuItem: observable,
      openMenu: action,
      closeMenu: action,
      toggleMenu: action,
      openMenuItem: action,
    });
  }

  openMenu() {
    this.isMenuOpen = true;
  }

  closeMenu() {
    this.isMenuOpen = false;
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  openMenuItem(menuItem) {
    this.activeMenuItem = menuItem;
  }

  isMenuItemOpen(menuItem) {
    return this.activeMenuItem === menuItem;
  }

  isGroupOpen(group) {
    return this.activeMenuItem?.includes(group.id);
  }
}

export const themeStore = new ThemeStore();
export const ThemeStoreContext = createContext(themeStore);
export const ThemeStoreProvider = ({ children, store }) => (
  <ThemeStoreContext.Provider value={store}>
    {children}
  </ThemeStoreContext.Provider>
);
export const useThemeStore = () => useContext(ThemeStoreContext);
