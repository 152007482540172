import booleanPointInPolygon from "@turf/boolean-point-in-polygon";
import Point from "turf-point";
import Polygon from "turf-polygon";

// Accepts coordinates of Point and coordinates of Polygon
// Returns if Point is inside Polygon
//
export const isPointInPoly = (pointCoords, polygonCoords) => {
  const point = Point(pointCoords);
  const polygon = Polygon([polygonCoords]);

  return booleanPointInPolygon(point, polygon);
}

// Accepts coordinates of Point and array of polygons
// Returns if point is inside any of the provided polygons
//
export const isPointInMultiPolys = (point, polygons) => {
  let isInside = false;

  for (const polygon of polygons) {
    if (isPointInPoly(point, polygon)) {
      isInside = true;
      break;
    }
  }

  return isInside;
}

// Accepts MultiPolygon
// Returns MultiPolygon processed to be accepted as multiple polygons path for Google Maps initialization
//
export const convertPolygonToGooglePaths = (multiPolygon) => {
  const converted = [];

  for (const poly of multiPolygon) {
    converted.push(poly[0].map(item => ({ lng: item[0], lat: item[1] })))
  }

  return converted;
}

// Accepts MultiPolygon
// Returns MultiPolygon with last coordinates identical to first (closed polygon)
//
// e.g. a triangle of 3 coordinates becomes a triangle of 4 (with last coord identical to first)
//
export const convertPolygonToClosedPolygon = (multiPolygon) => {
  const converted = [];

  for (const poly of multiPolygon) {
    converted.push([...poly[0], poly[0][0]])
  }

  return converted;
}